import { defineStore } from "pinia";
import lodash from "lodash";
import {
  addCustomTimePeriod,
  calculateTotalBudget,
  getFirstAndLastDates,
  removeCustomTimePeriod,
  restorePacingValues,
  transformInboundData,
} from "~/utils";
import useRefreshToken from "~/composables/useRefreshToken";
import { getBrands, getCampaignData, getPlatforms } from "~/composables/useFetchData";

const { refreshToken } = useRefreshToken();
const dataInfo: PlatformDataType = {
  id: undefined,
  firstObjective: "",
  secondObjective: { parentObjective: "", objective: "" },
  selectedAudiences: [],
  budgetAndSchedule: {
    audienceBudgetDistribution: false,
    startDate: null,
    tempStartDateEven: null,
    tempStartDateCustom: null,
    endDate: null,
    tempEndDateEven: null,
    tempEndDateCustom: null,
    budget: "",
    tempBudgetEven: "",
    tempBudgetCustom: "",
    tempBudgetAudienceEven: "",
    tempBudgetAudienceCustom: "",
    pacing: "pace_evenly",
    timePeriods: [],
    tempTimePeriods: [],
    audiencesBudgetEven: [],
  },
  completed: false,
  // Audiences table
  selectedRegionPage: null,
  selectedSortedColumn: "",
  selectedSortOrder: "none",
};
const newTimePeriod: Omit<TimePeriodsType, "id"> = {
  startDate: null,
  endDate: null,
  budget: "",
  audiencesBudgetCustom: [],
};

export const useCampaignStore = defineStore("campaignStore", {
  state: (): CampaignState => ({
    step: 0,
    loading: true,
    brands: [],
    platforms: [],
    displayId: "",
    selectedBrand: "",
    selectedCampaignName: "",
    selectedDuplicateId: "",
    campaignCreation: {},
  }),
  getters: {
    campaignCreationState(state) {
      return {
        brand: state.brands.find((brand) => brand.id === state.selectedBrand)?.name,
        name: state.selectedCampaignName,
        platforms: Object.entries(state.campaignCreation).map(([id, data]) => ({
          id,
          completed: data.completed,
          first_objective: data.firstObjective,
          second_objective: data.secondObjective.objective,
          pacing: data.budgetAndSchedule.pacing,
          budget: data.budgetAndSchedule.budget,
          audiences: data.selectedAudiences,
          audience_budget_distribution: data.budgetAndSchedule.audienceBudgetDistribution,
          audience_budgets_even: data.budgetAndSchedule.audiencesBudgetEven,
          start_date: data.budgetAndSchedule.startDate,
          end_date: data.budgetAndSchedule.endDate,
          time_periods: data.budgetAndSchedule.timePeriods.map((timePeriod) => ({
            id: timePeriod.id,
            start_date: timePeriod.startDate,
            end_date: timePeriod.endDate,
            budget: timePeriod.budget,
            audience_budgets: timePeriod.audiencesBudgetCustom,
          })),
        })),
      };
    },
    isBudgetAudienceDistributionAndPacingCustom(state) {
      return (
        state.campaignCreation[state.displayId].budgetAndSchedule.audienceBudgetDistribution &&
        state.campaignCreation[state.displayId].budgetAndSchedule.pacing === "custom_pacing"
      );
    },
    validStepOne(state) {
      return state.displayId !== "";
    },
    validStepTwo(state) {
      return state.campaignCreation[state.displayId]?.firstObjective !== "";
    },
    validStepThree(state) {
      return state.campaignCreation[state.displayId]?.secondObjective.objective !== "";
    },
    validStepFour(state) {
      return state.campaignCreation[state.displayId]?.selectedAudiences.length > 0;
    },
    validStepFive(state) {
      const budgetAndSchedule = state.campaignCreation[state.displayId]?.budgetAndSchedule;
      const pacing = budgetAndSchedule?.pacing;
      const audienceBudgetDistribution = budgetAndSchedule?.audienceBudgetDistribution;
      const timePeriods = budgetAndSchedule?.timePeriods;

      if (audienceBudgetDistribution && pacing === "pace_evenly") {
        return (
          budgetAndSchedule.startDate !== null &&
          budgetAndSchedule.endDate !== null &&
          !budgetAndSchedule.audiencesBudgetEven.includes("") &&
          budgetAndSchedule.audiencesBudgetEven.length > 0
        );
      } else if (!audienceBudgetDistribution && pacing === "pace_evenly") {
        return (
          budgetAndSchedule.tempBudgetEven !== "" &&
          budgetAndSchedule.startDate !== null &&
          budgetAndSchedule.endDate !== null
        );
      } else if (!audienceBudgetDistribution && pacing === "custom_pacing") {
        return !timePeriods.some(
          (period) => period.budget === "" || period.startDate === null || period.endDate === null,
        );
      } else if (audienceBudgetDistribution && pacing === "custom_pacing") {
        return !timePeriods.some(
          (period) =>
            period.startDate === null ||
            period.endDate === null ||
            period.audiencesBudgetCustom.includes("") ||
            period.audiencesBudgetCustom.length === 0,
        );
      }
      return false;
    },
    firstSteps(state) {
      return state.step === 1 || state.step === 2 || state.step === 3;
    },
    disableNextButton() {
      if (this.step === 1) {
        return !this.validStepOne;
      } else if (this.step === 2) {
        return !this.validStepTwo;
      } else if (this.step === 3) {
        return !this.validStepThree;
      } else if (this.step === 4) {
        return !this.validStepFour;
      } else if (this.step === 5) {
        return !this.validStepFive;
      } else {
        return true;
      }
    },
  },
  actions: {
    setLoading(loading: boolean) {
      this.loading = loading;
    },
    setStep(step: number) {
      this.step = step;
      if (step === 6) {
        this.campaignCreation[this.displayId].completed = true;
      }
    },
    setStepBack() {
      this.step--;
    },
    setStepNext() {
      this.step++;
      const currentStep = this.step;
      if (currentStep === 6) {
        this.campaignCreation[this.displayId].completed = true;
      }
      if (currentStep === 7) {
        this.resetCampaignCreation();
      }
    },
    setPlatform(platformId: string) {
      this.displayId = platformId;
      if (!this.campaignCreation[platformId]) {
        this.campaignCreation[platformId] = lodash.cloneDeep(dataInfo);
      }
    },
    setSelectedBrand(brandId: string) {
      this.selectedBrand = brandId;
    },
    setFirstObjective(firstObjective: string) {
      this.campaignCreation[this.displayId].firstObjective = firstObjective;
      if (this.campaignCreation[this.displayId].secondObjective.parentObjective === "") {
        this.campaignCreation[this.displayId].secondObjective.parentObjective = firstObjective;
      } else if (
        this.campaignCreation[this.displayId].secondObjective.parentObjective !==
        this.campaignCreation[this.displayId].firstObjective
      ) {
        this.campaignCreation[this.displayId].secondObjective.parentObjective = firstObjective;
        this.campaignCreation[this.displayId].secondObjective.objective = "";
      }
    },
    setAudienceBudgetDistribution(audienceBudgetDistribution: boolean) {
      this.campaignCreation[this.displayId].budgetAndSchedule.audienceBudgetDistribution = audienceBudgetDistribution;
      if (audienceBudgetDistribution) {
        if (this.campaignCreation[this.displayId].budgetAndSchedule.pacing === "pace_evenly") {
          this.campaignCreation[this.displayId].budgetAndSchedule.budget =
            this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetAudienceEven;
        } else {
          this.campaignCreation[this.displayId].budgetAndSchedule.budget =
            this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetAudienceCustom;
        }
      } else if (this.campaignCreation[this.displayId].budgetAndSchedule.pacing === "pace_evenly") {
        this.campaignCreation[this.displayId].budgetAndSchedule.budget =
          this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetEven;
      } else {
        this.campaignCreation[this.displayId].budgetAndSchedule.budget =
          this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetCustom;
      }
    },
    setAudience(audience: AudienceType) {
      const currentIds = this.campaignCreation[this.displayId].selectedAudiences.map(
        (audience) => audience.audience_id,
      );
      const currentAudiences = this.campaignCreation[this.displayId].selectedAudiences;
      // Manually updating audienceIds to avoid unnecessary reactivity
      this.campaignCreation[this.displayId].selectedAudiences = currentIds.includes(audience.audience_id)
        ? currentAudiences.filter((item) => item.audience_id !== audience.audience_id)
        : [...currentAudiences, audience];
    },
    setBudget(budget: string) {
      this.campaignCreation[this.displayId].budgetAndSchedule.budget = budget;
      this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetEven = budget;
    },
    setStartDate(startDate: Date) {
      this.campaignCreation[this.displayId].budgetAndSchedule.startDate = startDate;
      this.campaignCreation[this.displayId].budgetAndSchedule.tempStartDateEven = startDate;
    },
    setEndDate(endDate: Date) {
      this.campaignCreation[this.displayId].budgetAndSchedule.endDate = endDate;
      this.campaignCreation[this.displayId].budgetAndSchedule.tempEndDateEven = endDate;
    },
    setPacing(pacing: string) {
      this.campaignCreation[this.displayId].budgetAndSchedule.pacing = pacing;

      let tempBudgetCustom = "";
      let tempBudgetAudienceCustom = "";
      let tempBudgetEven = "";
      let tempBudgetAudienceEven = "";

      if (pacing === "custom_pacing") {
        // Restore custom time periods if they exist
        if (this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods.length > 0) {
          this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods = [
            ...this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods,
          ];
        } else {
          // Use addCustomTimePeriod to create new time periods
          this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods = addCustomTimePeriod(
            this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods,
            newTimePeriod,
          );
          this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods = addCustomTimePeriod(
            this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods,
            newTimePeriod,
          );
        }
        // Set the custom pacing budgets
        tempBudgetCustom = this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetCustom;
        tempBudgetAudienceCustom = this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetAudienceCustom;
        // Restore start and end dates for custom pacing
        this.campaignCreation[this.displayId].budgetAndSchedule.startDate =
          this.campaignCreation[this.displayId].budgetAndSchedule.tempStartDateCustom;
        this.campaignCreation[this.displayId].budgetAndSchedule.endDate =
          this.campaignCreation[this.displayId].budgetAndSchedule.tempEndDateCustom;
      } else if (pacing === "pace_evenly") {
        // Save current custom time periods to temporary storage
        this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods = [
          ...this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods,
        ];
        this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods = [];
        // Set the even pacing budgets
        tempBudgetEven = this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetEven;
        tempBudgetAudienceEven = this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetAudienceEven;
        // Restore start and end dates for pace_evenly pacing
        this.campaignCreation[this.displayId].budgetAndSchedule.startDate =
          this.campaignCreation[this.displayId].budgetAndSchedule.tempStartDateEven;
        this.campaignCreation[this.displayId].budgetAndSchedule.endDate =
          this.campaignCreation[this.displayId].budgetAndSchedule.tempEndDateEven;
      }
      // Restore the appropriate budget based on pacing and audienceBudgetDistribution
      this.campaignCreation[this.displayId].budgetAndSchedule.budget = restorePacingValues(
        pacing,
        this.campaignCreation[this.displayId].budgetAndSchedule.audienceBudgetDistribution,
        tempBudgetCustom,
        tempBudgetAudienceCustom,
        tempBudgetEven,
        tempBudgetAudienceEven,
      );
    },
    addCustomTimePeriod() {
      this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods = addCustomTimePeriod(
        this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods,
        newTimePeriod,
      );
      this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods = addCustomTimePeriod(
        this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods,
        newTimePeriod,
      );
    },
    deleteCustomTimePeriod(index: number) {
      this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods = removeCustomTimePeriod(
        this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods,
        index,
      );
      this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods = removeCustomTimePeriod(
        this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods,
        index,
      );
    },
    setTimePeriodBudget(budget: string, index: number) {
      this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods[index].budget = budget;
      this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods[index].budget = budget;
      // Calculate the total budget for time periods
      const timePeriodBudgets = this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods.map(
        (period) => period.budget,
      );
      const accumulatedBudget = calculateTotalBudget(timePeriodBudgets);
      // Set accumulated budget
      this.campaignCreation[this.displayId].budgetAndSchedule.budget = accumulatedBudget;
      this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetCustom = accumulatedBudget;
    },
    setTimePeriodStartDate(startDate: Date, index: number) {
      // Update the start date for the specific time period
      this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods[index].startDate = startDate;
      this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods[index].startDate = startDate;
      // Calculate the overall first start date using the utility function
      const { startDate: firstDate } = getFirstAndLastDates(
        this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods,
      );
      // Update the overall campaign start date
      this.campaignCreation[this.displayId].budgetAndSchedule.startDate = firstDate;
      this.campaignCreation[this.displayId].budgetAndSchedule.tempStartDateCustom = firstDate;
    },
    setTimePeriodEndDate(endDate: Date, index: number) {
      // Update the end date for the specific time period
      this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods[index].endDate = endDate;
      this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods[index].endDate = endDate;
      // Calculate the overall last end date using the utility function
      const { endDate: lastDate } = getFirstAndLastDates(
        this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods,
      );
      // Update the overall campaign end date
      this.campaignCreation[this.displayId].budgetAndSchedule.endDate = lastDate;
      this.campaignCreation[this.displayId].budgetAndSchedule.tempEndDateCustom = lastDate;
    },
    setBudgetAudienceEven(budget: string, index: number) {
      this.campaignCreation[this.displayId].budgetAndSchedule.audiencesBudgetEven[index] = budget;
      // Calculate total budget for audiences
      const audienceBudgetsEven = this.campaignCreation[this.displayId].budgetAndSchedule.audiencesBudgetEven;
      const accumulatedBudget = calculateTotalBudget(audienceBudgetsEven);
      // Set temporary budget
      this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetAudienceEven = accumulatedBudget;
      // Update the main budget based on audienceBudgetDistribution
      const pacing = this.campaignCreation[this.displayId].budgetAndSchedule.pacing;
      if (
        this.campaignCreation[this.displayId].budgetAndSchedule.audienceBudgetDistribution &&
        pacing === "pace_evenly"
      ) {
        this.campaignCreation[this.displayId].budgetAndSchedule.budget = accumulatedBudget;
      }
    },
    setBudgetAudienceCustom(budget: string, timePeriodIndex: number, audienceIndex: number) {
      const timePeriods = this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods;
      // Ensure reactivity: update the specific audience budget
      const updatedTimePeriod = {
        ...timePeriods[timePeriodIndex],
        audiencesBudgetCustom: [
          ...timePeriods[timePeriodIndex].audiencesBudgetCustom.slice(0, audienceIndex),
          budget,
          ...timePeriods[timePeriodIndex].audiencesBudgetCustom.slice(audienceIndex + 1),
        ],
      };
      // Replace the updated time period in the timePeriods array
      this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods = [
        ...timePeriods.slice(0, timePeriodIndex),
        updatedTimePeriod,
        ...timePeriods.slice(timePeriodIndex + 1),
      ];
      // Calculate the total custom audience budget for all time periods
      const customAudienceBudgets = this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods.flatMap(
        (period) => period.audiencesBudgetCustom,
      );
      const accumulatedBudget = calculateTotalBudget(customAudienceBudgets);
      // Set temporary budget for custom pacing
      this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetAudienceCustom = accumulatedBudget;
      // Update the main budget if pacing is custom and audience budget distribution is enabled
      const pacing = this.campaignCreation[this.displayId].budgetAndSchedule.pacing;
      if (
        this.campaignCreation[this.displayId].budgetAndSchedule.audienceBudgetDistribution &&
        pacing === "custom_pacing"
      ) {
        this.campaignCreation[this.displayId].budgetAndSchedule.budget = accumulatedBudget;
      }
    },
    deletePlatform(platformId: string) {
      delete this.campaignCreation[platformId];
      if (Object.keys(this.campaignCreation).length === 0) {
        this.campaignCreation = {};
        this.displayId = "";
        this.selectedBrand = "";
        this.selectedCampaignName = "";
        this.step = 1;
      } else {
        this.displayId = Object.keys(this.campaignCreation)[Object.keys(this.campaignCreation).length - 1];
      }
    },
    resetDisplayId() {
      this.displayId = "";
    },
    resetCampaignCreation() {
      this.campaignCreation = {};
      this.displayId = "";
      this.selectedBrand = "";
      this.selectedCampaignName = "";
    },
    navigateToFirstObjective(displayId: string) {
      this.displayId = displayId;
      this.setStep(2);
    },
    navigateToSecondObjective(displayId: string) {
      this.displayId = displayId;
      this.setStep(3);
    },
    navigateToAudiences(displayId: string) {
      this.displayId = displayId;
      this.setStep(4);
    },
    navigateToBudgetAndSchedule(displayId: string) {
      this.displayId = displayId;
      this.setStep(5);
    },
    // Table
    setSelectedSegmentPage(value: number | null) {
      this.campaignCreation[this.displayId].selectedRegionPage = value;
    },
    setSelectedSortedColumn(value: string) {
      this.campaignCreation[this.displayId].selectedSortedColumn = value;
    },
    setSelectedSortOrder(value: SortOrdersType) {
      this.campaignCreation[this.displayId].selectedSortOrder = value;
    },
    async getOptions() {
      if (this.brands.length === 0 || this.platforms.length === 0) {
        // TODO: Change new audiences endpoint
        const [brands, platforms] = await Promise.all([
          await getBrands(),
          await getPlatforms<{ results: PlatformType[] }>({
            reports: false,
            list_platforms: true,
            status: "active",
          }),
        ]);
        if (brands.error.value?.statusCode === 401 || platforms.error.value?.statusCode === 401) {
          await refreshToken();
        } else if (brands.data.value && platforms.data.value) {
          this.brands = brands.data.value.results
            ? Object.entries(brands.data.value.results)
                .map(([id, name]) => ({ id, name }))
                .sort((a, b) => a.name.localeCompare(b.name))
            : [];
          this.platforms = platforms.data.value.results ?? [];
        }
      }
    },
    async getExistingData(id: string, isDuplicate = false) {
      const { data: campaignData, error: campaignError } = await getCampaignData(id);
      if (campaignError.value?.statusCode === 401) {
        await refreshToken();
      } else if (campaignError.value?.statusCode === 404) {
        navigateTo("/campaigns/");
      } else if (campaignData.value) {
        await this.setCampaignState(campaignData.value, isDuplicate);
      }
    },
    async setCampaignState(inboundData: CampaignResponseType, isDuplicate = false) {
      // Ensure brands and platforms are loaded first
      await this.getOptions();

      // Transform and set the rest of the state
      const transformedData = transformInboundData(inboundData, isDuplicate);
      this.id = transformedData.id;
      this.selectedBrand = transformedData.selectedBrand;
      this.selectedCampaignName = transformedData.selectedCampaignName;
      this.campaignCreation = transformedData.campaignCreation;
      this.step = transformedData.step;
      this.displayId = transformedData.displayId;
      this.loading = transformedData.loading;
    },
  },
});
